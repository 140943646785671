import React from 'react';
import MaskedInput from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { REGEXP } from 'consts';
import { Button, CurrencyInput, Dropdown, Input, RadioButton } from 'components';

const propertyTypeOptions = [
  'House',
  'Condo',
  'Townhouse',
  'Multi-family',
  'Land',
  'Other'
].map(item => ({ value: item, text: item }));

const parkingAndStorageOptions = ['Deeded', 'LCE', 'Assigned'].map(item => ({
  value: item,
  text: item
}));

interface PropertyInfoFormProps {
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: { [key: string]: any; }) => void;
}

const PropertyInfoForm: React.FC<PropertyInfoFormProps> = ({ onSubmit, defaultValues }) => {
  const { register, control, errors, setValue, watch, handleSubmit } = useForm({ defaultValues });
  const { condoPUD, propertyType } = watch(['condoPUD', 'propertyType']);
  const isCondo = propertyType === 'Condo';

  const handlePropertyTypeChange = value => {
    if (value !== 'Condo') setValue('condoPUD', undefined);
    else setValue('lotSize', undefined);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="process-form__title">My Property is:</h3>
      <div className="process-form__radio-block">
        <RadioButton
          ref={register({ required: true })}
          name="saleOrRent"
          value="sale"
          label="For Sale"
          defaultChecked
          buttonLike
        />
        <RadioButton
          ref={register({ required: true })}
          name="saleOrRent"
          value="rent"
          label="For Rent"
          buttonLike
        />
      </div>
      <div className="process-form__input-block">
        <Controller
          control={control}
          name="propertyType"
          rules={{ required: 'Required' }}
          defaultValue={null}
          render={({ onChange, ...controllerProps }) => (
            <Dropdown
              options={propertyTypeOptions}
              error={errors.propertyType}
              className="process-form__input"
              label="Select Property Type*"
              placeholder="Select"
              onChange={value => {
                handlePropertyTypeChange(value);
                onChange(value);
              }}
              {...controllerProps}
            />
          )}
        />

        <div className="process-form__input-block">
          <Input
            className="process-form__input"
            ref={register({ required: 'Required' })}
            name="beds"
            error={errors.beds}
            onChange={e => setValue('beds', +e.target.value)}
            label="Enter Number of Bedrooms*"
            placeholder="Enter"
            type="number"
          />
          <Input
            className="process-form__input"
            ref={register({ required: 'Required' })}
            name="baths"
            error={errors.baths}
            label="Enter Number of Bathrooms*"
            onChange={e => setValue('baths', +e.target.value)}
            placeholder="Enter"
            type="number"
          />
        </div>
      </div>
      {isCondo && (
        <div className="process-form__radio-block margin-top">
          <div className="label">Condo PUD*</div>
          <RadioButton
            ref={register({ required: true })}
            name="condoPUD"
            value="yes"
            label="Yes"
            defaultChecked
          />
          <RadioButton ref={register({ required: true })} name="condoPUD" value="no" label="No" />
        </div>
      )}

      <div className="process-form__input-block process-form__input-block_short">
        <Input
          className="process-form__input"
          ref={register({ required: 'Required' })}
          name="sqft"
          error={errors.sqft}
          label="Living area square feet*"
          onChange={e => setValue('sqft', +e.target.value)}
          type="number"
          placeholder="Enter amount"
        />
        {!isCondo && (
          <Input
            className="process-form__input"
            ref={register({ required: 'Required' })}
            name="lotSize"
            error={errors.lotSize}
            label="Lot Size*"
            onChange={e => setValue('lotSize', +e.target.value)}
            type="number"
            placeholder="Enter amount in Sqft or Acres"
          />
        )}
      </div>
      <div className="process-form__input-block">
        {/* if Condo PUD yes */}
        {(!condoPUD || condoPUD === 'yes') && isCondo && (
          <>
            <Input
              className="process-form__input"
              ref={register}
              name="condo_parking_number"
              error={errors.condo_parking_number}
              label="Parking Number"
              placeholder="Enter Number"
            />
            <Controller
              control={control}
              name="condo_parking_info"
              defaultValue={null}
              render={controllerProps => (
                <Dropdown
                  options={parkingAndStorageOptions}
                  error={errors.condo_parking_info}
                  className="process-form__input"
                  label="Parking Information"
                  placeholder="Deeded, LCE, or Assigned"
                  {...controllerProps}
                />
              )}
            />
            <Input
              className="process-form__input"
              ref={register}
              name="condo_storage_number"
              error={errors.condo_storage_number}
              label="Storage Number"
              placeholder="Enter Number"
            />
            <Controller
              control={control}
              name="condo_storage_info"
              defaultValue={null}
              render={controllerProps => (
                <Dropdown
                  options={parkingAndStorageOptions}
                  error={errors.condo_storage_info}
                  className="process-form__input"
                  label="Storage Information"
                  placeholder="Deeded, LCE, or Assigned"
                  {...controllerProps}
                />
              )}
            />
          </>
        )}
      </div>

      {propertyType === 'Condo' && (
        <>
          <h3 className="process-form__title">Management Company Contact:</h3>
          <div className="process-form__input-block">
            <Input
              className="process-form__input"
              ref={register({ required: 'Required' })}
              name="managementCompanyContact.name"
              error={errors.managementCompanyContact?.name}
              label="Name*"
              placeholder="Enter Name"
            />
            <Input
              className="process-form__input"
              ref={register({ required: 'Required' })}
              name="managementCompanyContact.email"
              error={errors.managementCompanyContact?.email}
              label="Email*"
              placeholder="Enter email"
            />
            <Controller
              control={control}
              name="managementCompanyContact.phone"
              rules={{
                required: 'Required',
                validate: value => !value.includes('_') || 'Required'
              }}
              defaultValue=""
              render={controllerProps => (
                <Input
                  className="process-form__input"
                  as={MaskedInput}
                  mask="+1 (999) 999-9999"
                  type="tel"
                  error={errors.managementCompanyContact?.phone}
                  label="Phone Number*"
                  placeholder="Enter Phone Number"
                  {...controllerProps}
                />
              )}
            />
            <br />
            <Controller
              control={control}
              name="managementCompanyContact.assessments"
              defaultValue=""
              render={controllerProps => (
                <CurrencyInput
                  label="Assessments ($)"
                  placeholder="Enter Assessments ($)"
                  error={errors.managementCompanyContact?.assessments}
                  className="process-form__input"
                  {...controllerProps}
                />
              )}
            />
            <Controller
              control={control}
              name="managementCompanyContact.assessmentsSpecial"
              defaultValue=""
              render={controllerProps => (
                <CurrencyInput
                  label="Special assessments ($)"
                  placeholder="Enter Assessments ($)"
                  error={errors.managementCompanyContact?.assessmentsSpecial}
                  className="process-form__input"
                  {...controllerProps}
                />
              )}
            />
          </div>
        </>
      )}

      <h3 className="process-form__title">Owner Information:</h3>
      <div className="process-form__input-block">
        <Input
          className="process-form__input"
          ref={register({ required: 'Required' })}
          name="firstName"
          error={errors.firstName}
          label="First Name*"
          placeholder="Enter Name"
        />
        <Input
          className="process-form__input"
          ref={register({ required: 'Required' })}
          name="lastName"
          error={errors.lastName}
          label="Last Name*"
          placeholder="Enter Last Name"
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            required: 'Required',
            validate: value => !value.includes('_') || 'Required'
          }}
          defaultValue=""
          render={controllerProps => (
            <Input
              className="process-form__input"
              as={MaskedInput}
              mask="+1 (999) 999-9999"
              type="tel"
              error={errors.phone}
              label="Phone Number*"
              placeholder="Enter Phone Number"
              {...controllerProps}
            />
          )}
        />
        <Input
          className="process-form__input"
          ref={register({
            required: 'Required',
            maxLength: {
              value: 254,
              message: `Email can not exceed 254 characters`
            },
            pattern: {
              value: REGEXP.EMAIL,
              message: 'Invalid email format'
            }
          })}
          name="email"
          label="Email*"
          type="email"
          placeholder="Enter Email"
          error={errors.email}
        />
      </div>

      <Button className="process-form__btn" primary type="submit">
        Continue
      </Button>
    </form>
  );
};

export default PropertyInfoForm;
