import queryString from 'query-string';
import { urlEncodeBody } from 'utils';
import { mapListing } from 'utils/mapping';
import { fetchWithToken } from 'apis';

export async function submitSellProcessForm(values, listingId: string): Promise<number> {
  const data = {
    data: JSON.stringify(values),
    listing_id: listingId
  };
  const response = await fetchWithToken('/sell', { method: 'PUT', body: urlEncodeBody(data) });
  const jsonData = await response.json();
  if (!jsonData.document_id) throw Error('Form submission failed.');

  return jsonData.document_id;
}

export async function obtainMlsId(
  address: string,
  address2?: string
): Promise<{
  listingMlsId: string;
  listing: ListingDetails;
}> {
  const params = queryString.stringify(
    {
      address,
      address2
    },
    { skipEmptyString: true }
  );
  const response = await fetchWithToken(`listings/obtain_mls_id/v2?${params}`);
  const data = await response.json();
  return {
    listingMlsId: data.listing_id,
    listing: mapListing(data.listing) as ListingDetails
  };
}
