import React, { useEffect, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { REGEXP, STATES } from 'consts';
import { Button, Dropdown, Input } from 'components';
import { getAddressComponents } from 'utils/places';
import InputPlaces from 'components/InputPlaces/InputPlaces';

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));
interface IdentifyFormProps {
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: { [key: string]: any; }) => void;
}

const IdentifyForm: React.FC<IdentifyFormProps> = ({ defaultValues, onSubmit }) => {
  const [location, setLocation] = useState();
  const { register, control, errors, setValue, handleSubmit } = useForm({
    defaultValues
  });

  const prefilledAddress = defaultValues?.address1;

  useEffect(() => {
    if (prefilledAddress) {
      const geoCoder = new window.google.maps.Geocoder;
      geoCoder.geocode({ address: prefilledAddress, componentRestrictions: { country: 'us' } }, (results) => {
        const address = results?.[0];
        const autocompleteAddress = address?.formatted_address || prefilledAddress;
        setValue('autocompleteAddress', autocompleteAddress);
        handleAddressChange(address);
      });
    }
  }, [prefilledAddress]);

  const handleAddressChange = (place) => {
    const addressComponents = getAddressComponents(place);

    if (!addressComponents) return;

    setValue('address1', addressComponents.address1);
    setValue('city', addressComponents.city);
    setValue('state', addressComponents.state);
    setValue('zip', addressComponents.zip);
    setLocation(addressComponents.location);
  };

  const submitIntercepted = useCallback(
    (values) => {
      const updatedValue = Boolean(location) ? { ...values, location } : values;
      onSubmit(updatedValue);
    },
    [location],
  );

  const checkKeyDown = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  return (
    <form className="process-form" onSubmit={handleSubmit(submitIntercepted)} onKeyDown={checkKeyDown}>
      <h3 className="process-form__title">Property Details</h3>
      <div className="process-form__input-block">
        <Controller
          control={control}
          name="autocompleteAddress"
          defaultValue=""
          render={({ onChange, ...controllerProps }) => (
            <InputPlaces
              className="process-form__input"
              label="Address"
              placeholder="Enter Address"
              onChange={(query, place) => {
                handleAddressChange(place);
                onChange(query);
              }}
              {...controllerProps}
            />
          )}
        />
      </div>
      <div className="process-form__input-block">
        <Input
          className="process-form__input"
          ref={register({
            required: 'Required',
            maxLength: {
              value: 100,
              message: `Address can not exceed 100 characters`
            },
            pattern: {
              value: REGEXP.ADDRESS,
              message: 'Invalid Address'
            }
          })}
          name="address1"
          error={errors.address1}
          label="Address line 1*"
          placeholder="Enter Address line 1"
        />
        <Input
          className="process-form__input"
          ref={register}
          name="address2"
          label="Address line 2"
          placeholder="Enter Address line 2"
        />
      </div>
      <div className="process-form__input-block">
        <Input
          className="process-form__input ref={register({ required: 'Required' })}"
          ref={register({ required: 'Required' })}
          name="city"
          error={errors.city}
          label="City*"
          placeholder="Enter City"
        />
        <div className="process-form__input-block">
          <Controller
            control={control}
            name="state"
            defaultValue={null}
            render={controllerProps => (
              <Dropdown
                className="process-form__input"
                error={errors.state}
                label="State"
                placeholder="Select State"
                options={statesOptions}
                {...controllerProps}
              />
            )}
          />
          <Input
            className="process-form__input"
            ref={register({
              required: 'Required',
              pattern: { value: REGEXP.POSTAL_CODE, message: 'Invalid ZIP code' },
              maxLength: { value: 5, message: 'Invalid ZIP code' }
            })}
            name="zip"
            error={errors.zip}
            label="Zip Code"
            type="string"
            placeholder="Enter Zip Code"
            maxLength={5}
          />
        </div>
      </div>
      <Button className="process-form__btn" primary>
        Continue
      </Button>
    </form>
  );
};

export default React.memo(IdentifyForm);
