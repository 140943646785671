import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input, { InputProps } from 'components/Input/Input';
import useCombinedRefs from 'hooks/useCombineRefs';
interface InputPlacesProps extends Omit<InputProps, 'onChange'> {
  options?: google.maps.places.AutocompleteOptions;
  onChange: (query: string | undefined, place?: google.maps.places.PlaceResult) => void;
}

type BoundsType = { southWest: [number, number]; northEast: [number, number] };
type LatLngBounds = google.maps.LatLngBounds;

const InputPlaces_BOUNDS_ILLINOIS: BoundsType = {
  southWest: [36.966, -91.502],
  northEast: [42.511, -87.5]
};

const getBounds = (bounds: BoundsType): LatLngBounds => {
  const {
    southWest: [south, west],
    northEast: [north, east]
  } = bounds;
  const southWest = new window.google.maps.LatLng(south, west);
  const northEast = new window.google.maps.LatLng(north, east);
  const _bounds = new google.maps.LatLngBounds(southWest, northEast);
  return _bounds;
};

const InputPlaces: React.FC<InputPlacesProps> = React.forwardRef(
  ({ options, onChange, value, ...props }, refProp) => {
    const ref = useRef(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(ref, refProp);

    useEffect(() => {
      if (!window.google.maps?.places) {
        // eslint-disable-next-line no-console
        console.error('Places api is not loaded');
        return;
      }

      const bounds = getBounds(InputPlaces_BOUNDS_ILLINOIS);

      const opts: google.maps.places.AutocompleteOptions = {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        bounds,
        ...options
      };
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current!, opts);
      autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete!.getPlace();
        const query = place?.formatted_address || inputRef.current?.value;
        onChange(query, place);
      });
    }, []);

    return (
      <Input
        ref={inputRef}
        defaultValue={value}
        autoComplete="none"
        onChange={e => onChange(e.target.value)}
        {...props}
      />
    );
  }
);

export default InputPlaces;
