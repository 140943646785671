import React from 'react';

import { formatCurrency } from 'utils/rifmFormatters';
import { Image } from 'components';
import noImage from 'assets/images/placeholder_no_image.jpg';

import { IMAGE_SIZES } from 'consts';

interface IdentifySummaryProps {
  data: Partial<ListingDetails>;
  onEdit: (value: SellProcessTabKey) => void;
}

const IdentifySummary: React.FC<IdentifySummaryProps> = ({ data, onEdit }) => {
  const propertyPhoto = data.images?.[0];
  const addressLine1 = data.address1 || data.googleAddress;

  return (
    <div className="review-wrapper">
      <div className="review-block">
        <div className="header-property-info">
          <h3 className="review-title">Your property:</h3>
          <span className="property-block">
            {propertyPhoto ? (
              <Image
                cloudFront={{ size: IMAGE_SIZES.small, key: propertyPhoto }}
                error={noImage}
                alt="mini-propetry"
              />
            ) : (
                <img src={noImage} alt="mini-propetry" />
              )}

            <span>
              {addressLine1} <br />
              {data.address1 && (
                `${data?.address2} ${data?.city}, ${data?.state} ${data?.zip}`
              )}
            </span>
          </span>
        </div>
        <div className="header-property-info" />
        {data?.price && (
          <div className="header-property-info">
            <h3 className="review-title">Desired Price:</h3>
            <p className="property-price">{formatCurrency(data.price)}</p>
          </div>
        )}
        <div className="header-property-info info-block__align-right">
          <button className="info-block__edit-btn x" onClick={() => onEdit('identify')}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdentifySummary;
